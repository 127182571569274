import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Footer from './Footer';
import { CheckCircle2, Globe, ChevronDown } from 'lucide-react';
import { motion } from 'framer-motion';
import ReactPlayer from 'react-player';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Mobile detection hook
const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  return isMobile;
};

const translations = {
  en: {
    title: "Palette Hunt - AI Color Analysis & Virtual Try-On",
    description: "Discover your perfect color palette with Palette Hunt's AI-powered analysis. Transform your style, try on virtual looks, and get personalized recommendations. Unlock your true fashion potential today!",
    keywords: "color analysis, palette, color palette, palette hunt, palettehunt, AI styling, personal color palette, fashion technology, style recommendations",
    ogTitle: "Palette Hunt - AI-Powered Color Analysis",
    ogDescription: "Discover your perfect color palette with AI-powered analysis.",
    getStarted: "Get Color Analyzed Now",
    contact: "Contact",
    signIn: "Sign In",
    discover: "The Only",
    yourPerfect: "Color Analysis",
    colorPalette: "With AI Try-On",
    findYourShades: "Find your most flattering shades with our AI-powered color analysis. Upload your selfies and see yourself transformed in your perfect colors with stunning AI photos.",
    imagesCreated: "+15,000 images created",
  },
  es: {
    title: "Palette Hunt - Análisis de Color con IA para Moda y Estilo",
    description: "Descubre tu paleta de colores perfecta con el análisis impulsado por IA de Palette Hunt. Transforma tu estilo, prueba looks virtuales y obtén recomendaciones personalizadas. ¡Desbloquea tu verdadero potencial de moda hoy!",
    keywords: "análisis de color, paleta, paleta de colores, palette hunt, palettehunt, estilismo con IA, paleta de colores personal, tecnología de moda, recomendaciones de estilo",
    ogTitle: "Palette Hunt - Análisis de Color Impulsado por IA",
    ogDescription: "Descubre tu paleta de colores perfecta con análisis impulsado por IA.",
    getStarted: "Descubrir mis colores",
    contact: "Contacto",
    signIn: "Iniciar sesión",
    discover: "Descubre",
    yourPerfect: "Tu Perfecta",
    colorPalette: "Paleta de Colores",
    findYourShades: "Encuentra tus tonos más favorecedores con nuestro análisis de color impulsado por IA. Sube tus fotos para verte transformado en tus colores perfectos.",
    imagesCreated: "+15,000 imágenes creadas",
  },
  pt: {
    title: "Palette Hunt - Análise de Cores com IA para Moda e Estilo",
    description: "Descubra sua paleta de cores perfeita com a análise impulsionada por IA da Palette Hunt. Transforme seu estilo, experimente looks virtuais e obtenha recomendaciones personalizadas. Desbloqueie seu verdadeiro potencial de moda hoje!",
    keywords: "análise de cores, paleta, paleta de cores, palette hunt, palettehunt, estilismo com IA, paleta de cores pessoal, tecnologia de moda, recomendações de estilo",
    ogTitle: "Palette Hunt - Análise de Cores Impulsionada por IA",
    ogDescription: "Descubra sua paleta de colores perfeita com análise impulsionada por IA.",
    getStarted: "Descubrir meus colores",
    contact: "Contato",
    signIn: "Entrar",
    discover: "Descubra",
    yourPerfect: "Sua Perfeita",
    colorPalette: "Paleta de Cores",
    findYourShades: "Encontre seus tons mais favoráveis com nossa análise de cores impulsionada por IA. Faça o upload de suas fotos para se ver transformado em suas cores perfeitas.",
    imagesCreated: "+15.000 imagens criadas",
  }
};

const StepIndicator = ({ number, title }) => (
  <div className="flex items-center justify-center mb-6">
    <div className="flex items-center">
      <div className="w-8 h-8 rounded-full bg-gray-900 text-white flex items-center justify-center font-semibold text-sm">
        {number}
      </div>
      <div className="ml-3">
        <p className="text-gray-900 font-semibold">{title}</p>
      </div>
    </div>
  </div>
);

export const LogoCarousel = ({ title = "Featured on" }) => {
  return (
    <div className="relative overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <p className="text-center text-gray-600 text-lg mb-4 font-bold">{title}</p>
        <div className="relative max-w-2xl mx-auto">
          {/* Gradient overlays for fade effect */}
          <div className="absolute left-0 top-0 bottom-0 w-32 bg-gradient-to-r from-white to-transparent z-10"></div>
          <div className="absolute right-0 top-0 bottom-0 w-32 bg-gradient-to-l from-white to-transparent z-10"></div>
          
          {/* Sliding container */}
          <div className="overflow-hidden">
            <div 
              className="flex animate-scroll"
              style={{
                whiteSpace: 'nowrap',
                width: 'fit-content'
              }}
            >
              {/* Four copies of logos for smoother loop */}
              {[...Array(4)].map((_, index) => (
                <div 
                  key={index} 
                  className="flex items-center space-x-16 px-8"
                  style={{
                    minWidth: 'max-content'
                  }}
                >
                  <img src="/producthunt.png" alt="Product Hunt" className="h-20 object-contain" />
                  <img src="/toolify.webp" alt="Toolify" className="h-6 object-contain" />
                  <img src="/aipure.webp" alt="AI Pure" className="h-6 object-contain" />
                  <img src="/appstore.png" alt="App Store" className="h-6 object-contain" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .animate-scroll {
          animation: scroll 40s linear infinite;
        }
        
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }

        .animate-scroll:hover {
          animation-play-state: paused;
        }
      `}</style>
    </div>
  );
};


const HeroSection = ({ t, isMobile, isTablet, containerRef, svgRef, language, setLanguageMenuOpen, languageMenuOpen }) => {
  return (
    <>
      {/* Wrapper div that contains both Hero and LogoCarousel */}
      <div className="relative overflow-hidden">
        {/* Base white background with gradient blobs - only show on desktop */}
        {!isMobile && (
          <div className="absolute inset-0 bg-white">
            {/* Blue gradient blob */}
            <div className="absolute -left-1/4 top-1/4 h-[600px] w-[600px] animate-pulse rounded-full bg-blue-600/30 blur-[120px]" />
            
            {/* White gradient */}
            <div 
              className="absolute right-0 top-0 bottom-0 w-1/2"
              style={{
                background: 'linear-gradient(to right, transparent, white)'
              }}
            />
          </div>
        )}

        {/* Hero Content */}
        <div className={`relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ${isMobile ? 'pt-2' : 'pt-16'} pb-16`}>
          {isMobile ? (
            <div className="flex flex-col items-center">
              <div className="w-full text-center mb-8">
                <h1 className="text-4xl font-bold mb-4 text-gray-900">
                  <span className="block mb-2">{t.discover}</span>
                  <span className="block mb-2">{t.yourPerfect}</span>
                  <span className="block mb-2">{t.colorPalette}</span>
                </h1>
                <h2 className="text-lg text-gray-600 mb-6">
                  <span className="block mb-2">{t.findYourShades.split('.')[0]}.</span>
                  {t.findYourShades.split('.')[1].trim()}.
                </h2>
                <div className="flex flex-col gap-4 items-center">
                  <Link
                    to="/login"
                    className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-bold rounded-full text-white bg-blue-600 hover:bg-blue-700"
                  >
                    {t.getStarted}
                  </Link>
                </div>
              </div>
              <div className="w-full max-w-xs mx-auto">
                <img
                  src="/hero.png"
                  alt="Color Analysis Process"
                  className="w-full h-auto rounded-lg"
                />
              </div>
            </div>
          ) : (
            // Desktop Layout
            <div className="flex flex-row items-center">
              <div className="md:w-1/2 mb-8 md:mb-0 text-left">
                <h1 className="text-5xl sm:text-6xl md:text-7xl font-bold mb-4 sm:mb-8 text-left text-gray-900">
                  <span className="block mb-2">{t.discover}</span>
                  <span className="block mb-2">{t.yourPerfect}</span>
                  <span className="block mb-2">{t.colorPalette}</span>
                </h1>
                <h2 className="text-lg sm:text-xl text-gray-600 mb-6 sm:mb-10 max-w-none">
                  <span className="block mb-2">{t.findYourShades.split('.')[0]}.</span>
                  {t.findYourShades.split('.')[1].trim()}.
                </h2>
                <div className="flex flex-row gap-4 justify-start">
                  <Link
                    to="/login"
                    className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-bold rounded-full text-white bg-blue-600 hover:bg-blue-700"
                  >
                    {t.getStarted}
                  </Link>
                </div>
              </div>
              <div className="md:w-1/2 flex items-center justify-center px-4 md:px-0">
                <div className="relative w-full max-w-lg mx-auto">
                  <img
                    src="/hero.png"
                    alt="Color Analysis Process"
                    className="w-full h-auto rounded-lg"
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Logo Carousel */}
        <LogoCarousel />
      </div>
    </>
  );
};

const FeaturesSection = ({ isMobile }) => {
  return (
    <section className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-6">
            Transform Your Look with AI
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Upload a few photos and get AI-powered color analysis in one hour
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            className="bg-white p-8 rounded-2xl shadow-lg border border-gray-100 hover:shadow-xl transition-shadow duration-300"
          >
            <div className="flex items-center mb-6">
              <div className="w-12 h-12 bg-gray-900 rounded-full flex items-center justify-center text-white mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
              </div>
            </div>
            <h3 className="text-xl font-bold text-gray-900 mb-4">Upload Your Photos</h3>
            <p className="text-gray-600">Upload your best photos for your color analysis. Our AI will process your unique features.</p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="bg-white p-8 rounded-2xl shadow-lg border border-gray-100 hover:shadow-xl transition-shadow duration-300"
          >
            <div className="flex items-center mb-6">
              <div className="w-12 h-12 bg-gray-900 rounded-full flex items-center justify-center text-white mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01" />
                </svg>
              </div>
            </div>
            <h3 className="text-xl font-bold text-gray-900 mb-4">Get Your Colors</h3>
            <p className="text-gray-600">Receive your personalized color palette based on your unique features, skin tone, and undertones.</p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            className="bg-white p-8 rounded-2xl shadow-lg border border-gray-100 hover:shadow-xl transition-shadow duration-300"
          >
            <div className="flex items-center mb-6">
              <div className="w-12 h-12 bg-gray-900 rounded-full flex items-center justify-center text-white mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
            </div>
            <h3 className="text-xl font-bold text-gray-900 mb-4">See the Results</h3>
            <p className="text-gray-600">View yourself transformed in your perfect colors with our advanced AI technology. Experience the difference.</p>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

const PricingSection = ({ t }) => {
  return (
    <section className="bg-white py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-5xl font-bold text-center text-gray-900 mb-16">
          Choose Your Color Analysis Plan
        </h2>
        
        <div className="text-center mb-16">
          <h3 className="text-4xl font-bold text-gray-900 mb-4">{t.choosePlan}</h3>
          <p className="text-xl text-gray-600">{t.selectPlan}</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Basic Plan */}
          <div className="relative flex flex-col bg-white rounded-2xl shadow-lg border border-gray-100 p-8">
            <div className="flex-grow">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Basic Plan</h3>
              <div className="flex items-baseline mb-4">
                <span className="text-5xl font-bold text-gray-900">$6<span className="text-3xl">.99</span></span>
              </div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">Color analysis test</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">5 tailored color palettes</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">Gold or Silver test</span>
                </li>
              </ul>
            </div>
            <Link
              to="/login"
              className="w-full bg-gray-900 text-white rounded-full py-3 font-semibold hover:bg-gray-800 transition duration-300 text-center"
            >
              Get Basic
            </Link>
          </div>

          {/* Pro Plan */}
          <div className="relative flex flex-col bg-white rounded-2xl shadow-lg border-2 border-gray-900 p-8">
            <div className="absolute top-0 right-6 transform -translate-y-1/2">
              <span className="bg-gray-900 text-white px-3 py-1 rounded-full text-sm font-medium">Popular</span>
            </div>
            <div className="flex-grow">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Pro Plan</h3>
              <div className="flex items-baseline mb-4">
              <span className="text-5xl font-bold text-gray-900">$17<span className="text-3xl">.99</span></span>
              </div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">Color analysis test</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">5 tailored color palettes</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">12 AI photos</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">150 playground credits</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">Gold or Silver test</span>
                </li>
              </ul>
            </div>
            <Link
              to="/login"
              className="w-full bg-gray-900 text-white rounded-full py-3 font-semibold hover:bg-gray-800 transition duration-300 text-center"
            >
              Get Pro
            </Link>
          </div>

          {/* Enterprise Plan */}
          <div className="relative flex flex-col bg-white rounded-2xl shadow-lg border border-gray-100 p-8">
            <div className="flex-grow">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">Enterprise</h3>
              <div className="flex items-baseline mb-4">
                <span className="text-5xl font-bold text-gray-900">Custom</span>
              </div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">Tailored color analysis solutions</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">Bulk pricing options</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">Customised features</span>
                </li>
              </ul>
            </div>
            <a
              href="mailto:hi@palettehunt.com"
              className="w-full bg-gray-900 text-white rounded-full py-3 font-semibold hover:bg-gray-800 transition duration-300 text-center"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

const ImageWithFallback = ({ src, alt, className, objectPosition, isMobile }) => {
  const [error, setError] = useState(false);

  const handleError = (e) => {
    console.error(`Failed to load image: ${src}`);
    setError(true);
  };

  return (
    <div className={`relative ${className} ${!isMobile ? 'transition-transform duration-300 ease-in-out transform hover:scale-110' : ''}`}>
      {!error ? (
        <img
          src={src}
          alt={alt}
          className="w-full h-full object-cover rounded-lg"
          style={{ objectPosition: objectPosition || 'center' }}
          onError={handleError}
        />
      ) : (
        <div className="absolute inset-0 flex items-center justify-center bg-red-500 text-white text-xs p-2 text-center rounded-lg">
          Image failed to load: {src}
        </div>
      )}
    </div>
  );
};

const TestimonialsSection = ({ isMobile }) => {
  return (
    <section className={`testimonials bg-white ${!isMobile ? 'mt-[-2rem] mb-4' : ''}`}>
      <div className="max-w-6xl mx-auto px-4">
        <div className="grid md:grid-cols-2 gap-12">
          <div className="bg-white p-8 rounded-lg shadow-lg border border-gray-100">
            <p className="text-xl text-gray-600 italic mb-6 relative z-10">
              "I never thought yellow colors would suit me, but seeing myself in them has changed my mind completely."
            </p>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <h3 className="text-lg font-semibold text-gray-900 mr-2">Laura</h3>
                <span className="inline-block border border-green-500 text-green-500 text-xs px-2 py-1 rounded-full">
                ✓ Verified User
                </span>
              </div>
              <div className="flex">
                {[...Array(5)].map((_, i) => (
                  <svg key={i} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                ))}
              </div>
            </div>
          </div>
          <div className="bg-white p-8 rounded-lg shadow-lg border border-gray-100">
            <p className="text-xl text-gray-600 italic mb-6 relative z-10">
              "The AI-generated photos helped me visualize how different colors would actually look on me. It's amazing!"
            </p>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <h3 className="text-lg font-semibold text-gray-900 mr-2">Olympia</h3>
                <span className="inline-block border border-green-500 text-green-500 text-xs px-2 py-1 rounded-full">
                  ✓ Verified User
                </span>
              </div>
              <div className="flex">
                {[...Array(5)].map((_, i) => (
                  <svg key={i} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const ColorAnalysisSection = () => {
  return (
    <section className="bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Finally Understand What Colors Suit You
          </h2>
          <p className="text-gray-600 text-xl max-w-2xl mx-auto">
            Our AI-powered color analysis provides detailed explanations and shows you transformed in your perfect colors using advanced AI-generated photos.
          </p>
        </div>

        {/* Image Carousel */}
        <div className="relative max-w-4xl mx-auto mb-16">
          {/* Gradient overlays for fade effect */}
          <div className="absolute left-0 top-0 bottom-0 w-32 bg-gradient-to-r from-white to-transparent z-10"></div>
          <div className="absolute right-0 top-0 bottom-0 w-32 bg-gradient-to-l from-white to-transparent z-10"></div>
          
          <div className="overflow-hidden">
            <div className="flex animate-carousel">
              {[...Array(2)].map((_, setIndex) => (
                <div key={setIndex} className="flex space-x-8 flex-none">
                  {[...Array(9)].map((_, index) => (
                    <div key={`${setIndex}-${index}`} className="flex-none">
                      <img
                        src={`/carousel${index + 1}.jpeg`}
                        alt={`Color Analysis Example ${index + 1}`}
                        className="w-auto h-auto max-h-[300px] rounded-xl shadow-lg"
                      />
                    </div>
                  ))}
                  {/* Add spacing after each set */}
                  <div className="w-8 flex-none"></div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Features */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <div className="text-center">
            <div className="mb-4">
              <div className="w-16 h-16 mx-auto bg-gray-100 rounded-full flex items-center justify-center">
                <svg className="w-8 h-8 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                </svg>
              </div>
            </div>
            <h3 className="text-2xl font-semibold text-gray-900 mb-3">Personalized Analysis</h3>
            <p className="text-gray-600">Get a detailed analysis of your unique coloring and discover your perfect palette.</p>
          </div>

          <div className="text-center">
            <div className="mb-4">
              <div className="w-16 h-16 mx-auto bg-gray-100 rounded-full flex items-center justify-center">
                <svg className="w-8 h-8 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
                </svg>
              </div>
            </div>
            <h3 className="text-2xl font-semibold text-gray-900 mb-3">AI Transformations</h3>
            <p className="text-gray-600">See yourself transformed with AI-powered visualizations in your ideal colors.</p>
          </div>

          <div className="text-center">
            <div className="mb-4">
              <div className="w-16 h-16 mx-auto bg-gray-100 rounded-full flex items-center justify-center">
                <svg className="w-8 h-8 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                </svg>
              </div>
            </div>
            <h3 className="text-2xl font-semibold text-gray-900 mb-3">Expert Recommendations</h3>
            <p className="text-gray-600">Receive the most advanced guidance and practical tips for using your color palette.</p>
          </div>
        </div>
      </div>

      <style jsx>{`
        @keyframes carousel {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }
        
        .animate-carousel {
          animation: carousel 60s linear infinite;
        }
      `}</style>
    </section>
  );
};

const PlaygroundSection = ({ isMobile, isTablet }) => {
  return (
    <section className={`${isMobile ? 'py-24' : 'py-48'}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className={`flex flex-col ${isMobile ? 'items-center' : 'md:flex-row md:items-center md:space-x-12'}`}>
          <div className={`${isMobile ? 'w-full text-center' : 'md:w-1/2 text-left'} mb-12 md:mb-0`}>
            <h2 className={`${isMobile ? 'text-3xl' : 'text-4xl'} font-bold mb-6 text-gray-900`}>Your Style, Reinvented</h2>
            <p className={`${isMobile ? 'text-lg' : 'text-xl'} text-gray-600 mb-4`}>
              Step into our virtual try-on studio where imagination knows no bounds. See yourself in as many looks and colors as you want.
            </p>
            <p className={`${isMobile ? 'text-lg' : 'text-xl'} text-gray-600 mb-6`}>
              Create images of yourself in styles you never thought you could wear.
            </p>
          </div>
          <div className={`${isMobile ? 'w-full' : 'md:w-1/2'}`}>
            <img 
              src="/playground.png" 
              alt="Palette Hunt Try-on Studio" 
              className={`${isMobile ? 'w-3/4' : 'w-4/5'} mx-auto object-cover`} 
            />
          </div>
        </div>
      </div>
    </section>
  );
};

const ImageGridSection = ({ isMobile, isTablet, containerRef2, svgRef2 }) => {
  return (
    <section className="pt-24 pb-12 bg-white">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-gray-900 mb-12">
          See Yourself in Your Perfect Colors
        </h2>
        <p className="text-lg text-gray-600 max-w-3xl mx-auto text-center mb-8">
            Don't just read about colors that might suit you - see them in action on your personalized AI model. Our comprehensive color analysis shows you exactly how your perfect palette looks on you. <strong>All it takes is some selfies.</strong>
          </p>
        <div ref={containerRef2} className={`relative w-full ${isTablet ? 'md:h-[400px]' : 'md:h-[600px]'} mb-16 md:mb-0`}>
          <svg ref={svgRef2} className="absolute top-0 left-0 w-full h-full text-secondary pointer-events-none z-10" />
          <div className="flex flex-col md:flex-row justify-between items-center">
            {/* Left Column */}
            <div className="w-full md:w-[48%] mb-8 md:mb-0">
              <div className="relative">
                {/* Top row of small images */}
                <div className="flex space-x-3 mb-2">
                  <ImageWithFallback 
                    src="/grid1.png" 
                    alt="Grid 1" 
                    className="w-56 h-12 md:w-72 md:h-16 object-contain transition-transform duration-300 hover:scale-105" 
                  />
                </div>
                {/* Large images */}
                <div className="flex space-x-4">
                  <div className="relative w-1/2">
                    <ImageWithFallback 
                      src="/laura-large-2.png" 
                      alt="Laurita" 
                      className="w-full h-48 md:h-72 object-cover shadow-lg transition-transform duration-300 hover:scale-105" 
                    />
                    <span className="absolute top-2 left-2 bg-green-500 text-white text-xs px-2 py-1 rounded-full shadow-md">AI Photo</span>
                    <div className="mt-4 text-center">
                      <p className="text-gray-900 text-lg mb-3"><strong>Warm Spring 🌸</strong></p>
                      <div className="flex justify-center space-x-1">
                        <div className="w-6 h-6 rounded-full bg-[#FF7F50]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#FA8072]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#DAA520]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#B8860B]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#228B22]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#2E8B57]"></div>
                      </div>
                    </div>
                  </div>
                  <div className="relative w-1/2">
                    <ImageWithFallback 
                      src="/orange-2.jpg" 
                      alt="Sunny Yellow" 
                      className="w-full h-48 md:h-72 object-cover shadow-lg transition-transform duration-300 hover:scale-105" 
                    />
                    <span className="absolute top-2 left-2 bg-green-500 text-white text-xs px-2 py-1 rounded-full shadow-md">AI Photo</span>
                    <div className="mt-4 text-center">
                      <p className="text-gray-900 text-lg mb-3"><strong>Warm Autumn 🍁</strong></p>
                      <div className="flex justify-center space-x-1">
                        <div className="w-6 h-6 rounded-full bg-[#E57373]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#DDAA33]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#CD853F]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#556B2F]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#8B4513]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#DAA520]"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isMobile && <div className="mb-8"></div>}
            {/* Right Column */}
            <div className="w-full md:w-[48%] md:w-1/2">
              <div className="relative">
                {/* Top row of small images */}
                <div className="flex space-x-3 mb-2 justify-end">
                  <ImageWithFallback 
                    src="/grid2.png" 
                    alt="Grid 2" 
                    className="w-56 h-12 md:w-72 md:h-16 object-contain transition-transform duration-300 hover:scale-105" 
                  />
                </div>
                {/* Large images */}
                <div className="flex space-x-4">
                  <div className="relative w-1/2">
                    <ImageWithFallback 
                      src="/olympia_large_21.png" 
                      alt="Olympia AI 2" 
                      className="w-full h-56 md:h-72 object-cover shadow-lg transition-transform duration-300 hover:scale-102" 
                    />
                    <span className="absolute top-2 left-2 bg-green-500 text-white text-xs px-2 py-1 rounded-full shadow-md">AI Photo</span>
                    <div className="mt-4 text-center">
                      <p className="text-gray-900 text-lg mb-3"><strong>Soft Summer ☀️</strong></p>
                      <div className="flex justify-center space-x-1">
                        <div className="w-6 h-6 rounded-full bg-[#8B8589]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#778899]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#B8A6B3]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#9AA3A8]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#A7A69D]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#8C8C8C]"></div>
                      </div>
                    </div>
                  </div>
                  <div className="relative w-1/2">
                    <ImageWithFallback 
                      src="/olympia_ai_3.png" 
                      alt="Olympia Transform 1" 
                      className="w-full h-56 md:h-72 object-cover shadow-lg transition-transform duration-300 hover:scale-102" 
                    />
                    <span className="absolute top-2 left-2 bg-green-500 text-white text-xs px-2 py-1 rounded-full shadow-md">AI Photo</span>
                    <div className="mt-4 text-center">
                      <p className="text-gray-900 text-lg mb-3"><strong>Deep Autumn 🍂</strong></p>
                      <div className="flex justify-center space-x-1">
                        <div className="w-6 h-6 rounded-full bg-[#8B4513]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#DAA520]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#CD853F]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#A0522D]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#D2691E]"></div>
                        <div className="w-6 h-6 rounded-full bg-[#B8860B]"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const FAQSection = () => {
  return (
    <section className="bg-white py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-12 gap-x-16 lg:gap-x-24">
          {/* Left Column - Title */}
          <div className="md:col-span-5">
            <h2 className="text-6xl font-bold text-gray-900 top-8">
              Frequently Asked Questions
            </h2>
            <p className="mt-8 text-gray-600">
              Have another question? Contact me through {' '}
              <a href="mailto:hi@palettehunt.com" className="text-gray-900 hover:underline">email</a>.
            </p>
          </div>

          {/* Right Column - Questions */}
          <div className="md:col-span-7">
            {[
              {
                question: "How does Palette Hunt work?",
                answer: "We use the most advanced AI vision algorithms to analyze your photos and generate personalized color palettes that complement your unique features and style preferences."
              },
              {
                question: "How long does it take to get results?",
                answer: <>You will typically receive your results within 1h. If the results are taking more than 2h, please send us an email at <a href="mailto:hi@palettehunt.com" className="text-gray-900 hover:underline"><strong>hi@palettehunt.com</strong></a></>
              },
              {
                question: "Will the quality of the results be good?",
                answer: "Yes, we can assure you that the quality of the results will be the best you can get in the market. But you need to provide us with good quality photos to maximize the quality of the results."
              },
              {
                question: "Do I need to upload many pictures?",
                answer: "For optimal results, we recommend uploading 8-10 photos that showcase different angles, lighting conditions, and outfits. This will determine the quality of the results!"
              },
              {
                question: "Can I keep the generated images?",
                answer: "Absolutely! You own all the images generated by Palette Hunt. Both for the playground and for the color analysis."
              },
              {
                question: "Can I use Palette Hunt on mobile devices?",
                answer: "Yes, no problem! Palette Hunt works in either mobile or desktop. Feel free to use the device you are most comfortable with!"
              },
              {
                question: "How accurate are the color recommendations?",
                answer: "Our color recommendation process has been thoroughly tested to make sure our results are the best you can get. Our satisfaction rate is extremely high among our users."
              },
              {
                question: "Which type of photos will I get?",
                answer: "For each color palette you get, you will get 2 images of you in that color palette. One will have a white background, like in a studio, and one will have a background that matches the color theme."
              },
              {
                question: "What happens if I don't like the results?",
                answer: <>Quality is our top priority. At this time, we cannot offer full refunds as every color analysis means a signficant cost per user. We are sure though, we will find a solution for you. We are always available at <a href="mailto:hi@palettehunt.com" className="text-gray-900 hover:underline"><strong>hi@palettehunt.com</strong></a></>
              },
              {
                question: "Will my photos be shared with anyone?",
                answer: "No. We just use the photos to train our AI that no one else has access to. Every uploaded picture you upload gets deleted after a week."
              }
            ].map((item, index) => (
              <details key={index} className="group border-t border-gray-200 [&>summary::-webkit-details-marker]:hidden [&>summary::marker]:hidden">
                <summary className="flex justify-between items-center py-5 cursor-pointer text-xl font-semibold text-gray-900">
                  {item.question}
                  <span className="ml-6 flex-shrink-0 text-2xl group-open:rotate-45 transition-transform duration-200">
                    +
                  </span>
                </summary>
                <div className="pb-5 text-gray-600">
                  {item.answer}
                </div>
              </details>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const LandingPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth < 1024);
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const location = window.location.pathname;
  const [language, setLanguage] = useState(() => {
    if (location.startsWith('/es/')) return 'es';
    if (location.startsWith('/pt/')) return 'pt';
    return 'en';
  });
  const t = translations[language] || translations.en;
  const containerRef = useRef(null);
  const containerRef2 = useRef(null);
  const svgRef = useRef(null);
  const svgRef2 = useRef(null);

  const changeLanguage = (lang) => {
    setLanguage(lang);
    setLanguageMenuOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setIsTablet(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.language-menu')) {
        setLanguageMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const jsonLd = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebSite",
        "@id": "https://www.palettehunt.com/#website",
        "url": "https://www.palettehunt.com",
        "name": "Palette Hunt",
        "description": "AI-powered color analysis and virtual try-on platform",
        "publisher": {
          "@id": "https://www.palettehunt.com/#organization"
        },
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://www.palettehunt.com/search?q={search_term_string}",
          "query-input": "required name=search_term_string"
        },
        "inLanguage": "en-US"
      },
      {
        "@type": "Organization",
        "@id": "https://www.palettehunt.com/#organization",
        "name": "Palette Hunt",
        "url": "https://www.palettehunt.com",
        "logo": {
          "@type": "ImageObject",
          "url": "https://www.palettehunt.com/logo.png",
          "width": "512",
          "height": "512"
        },
        "sameAs": [
          "https://apps.apple.com/app/palette-hunt-color-analysis/id6737066020"
        ],
        "contactPoint": [
          {
            "@type": "ContactPoint",
            "email": "hi@palettehunt.com",
            "contactType": "customer service",
            "availableLanguage": ["en", "es", "pt"]
          }
        ]
      },
      {
        "@type": "SoftwareApplication",
        "@id": "https://www.palettehunt.com/#software",
        "name": "Palette Hunt",
        "operatingSystem": "iOS",
        "applicationCategory": "LifestyleApplication",
        "offers": {
          "@type": "Offer",
          "price": "0",
          "priceCurrency": "USD"
        }
      },
      {
        "@type": "Service",
        "@id": "https://www.palettehunt.com/#service",
        "name": "AI Color Analysis",
        "provider": {
          "@id": "https://www.palettehunt.com/#organization"
        },
        "description": "Discover your perfect color palette with AI-powered analysis. Transform your style with virtual try-on and get personalized recommendations.",
        "serviceType": "Color Analysis",
        "areaServed": {
          "@type": "Country",
          "name": "Worldwide"
        },
        "offers": [
          {
            "@type": "Offer",
            "name": "Basic Plan",
            "price": "6.99",
            "priceCurrency": "USD",
            "description": "Color analysis test, 5 tailored color palettes, Gold or Silver test",
            "availability": "https://schema.org/InStock"
          },
          {
            "@type": "Offer",
            "name": "Pro Plan",
            "price": "17.99",
            "priceCurrency": "USD",
            "description": "Color analysis test, 5 tailored color palettes, 12 AI photos, 150 playground credits, Gold or Silver test",
            "availability": "https://schema.org/InStock"
          }
        ]
      },
      {
        "@type": "Product",
        "@id": "https://www.palettehunt.com/#product",
        "name": "Palette Hunt Color Analysis",
        "description": "AI-powered color analysis and virtual try-on service",
        "brand": {
          "@type": "Brand",
          "name": "Palette Hunt",
          "logo": "https://www.palettehunt.com/logo.png"
        },
        "image": [
          "https://www.palettehunt.com/hero.png",
          "https://www.palettehunt.com/playground.png"
        ],
        "offers": {
          "@type": "AggregateOffer",
          "lowPrice": "6.99",
          "highPrice": "17.99",
          "priceCurrency": "USD",
          "offerCount": "2",
          "availability": "https://schema.org/InStock"
        },
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "5",
          "reviewCount": "2",
          "bestRating": "5",
          "worstRating": "1"
        },
        "review": [
          {
            "@type": "Review",
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "5",
              "bestRating": "5",
              "worstRating": "1"
            },
            "author": {
              "@type": "Person",
              "name": "Laura"
            },
            "reviewBody": "I never thought yellow colors would suit me, but seeing myself in them has changed my mind completely."
          },
          {
            "@type": "Review",
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "5",
              "bestRating": "5",
              "worstRating": "1"
            },
            "author": {
              "@type": "Person",
              "name": "Olympia"
            },
            "reviewBody": "The AI-generated photos helped me visualize how different colors would actually look on me. It's amazing!"
          }
        ],
        "additionalProperty": [
          {
            "@type": "PropertyValue",
            "name": "Features",
            "value": "AI-powered color analysis"
          },
          {
            "@type": "PropertyValue",
            "name": "Features",
            "value": "Virtual try-on"
          },
          {
            "@type": "PropertyValue",
            "name": "Features",
            "value": "Personalized recommendations"
          }
        ]
      },
      {
        "@type": "FAQPage",
        "@id": "https://www.palettehunt.com/#faq",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "How does Palette Hunt work?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "We use the most advanced AI vision algorithms to analyze your photos and generate personalized color palettes that complement your unique features and style preferences."
            }
          },
          {
            "@type": "Question",
            "name": "How long does it take to get results?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "You will typically receive your results within 1h. If the results are taking more than 2h, please send us an email at hi@palettehunt.com"
            }
          },
          {
            "@type": "Question",
            "name": "Will the quality of the results be good?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, we can assure you that the quality of the results will be the best you can get in the market. But you need to provide us with good quality photos to maximize the quality of the results."
            }
          }
        ]
      }
    ]
  };

  return (
    <>
      <Helmet>
        <title>{t.title}</title>
        <meta name="description" content={t.description} />
        <meta name="keywords" content={t.keywords} />
        <link rel="canonical" href="https://www.palettehunt.com" />
        <meta property="og:title" content={t.ogTitle} />
        <meta property="og:description" content={t.ogDescription} />
        <meta property="og:url" content="https://www.palettehunt.com" />
      </Helmet>
      <div className="min-h-screen flex flex-col">
        <header className="bg-white">
          <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
            <div className={`flex justify-between items-center`}>
              <Link to="/" className="flex items-center">
                <img src="/logo.png" alt="Palette Hunt Logo" className="h-8 w-auto" />
                {!isMobile && <span className="ml-2 text-xl font-bold text-gray-900">Palette Hunt</span>}
              </Link>
              <div className="flex items-center">
                {isMobile ? (
                  <div className="flex items-center space-x-4">
                    <Link
                      to="/login"
                      className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-bold rounded-full text-white bg-blue-600 hover:bg-blue-700"
                    >
                      {t.getStarted}
                    </Link>
                  </div>
                ) : (
                  <>
                    <div className="flex items-center space-x-8 mr-10">
                      <Link to="/contact" className="text-gray-700 hover:text-gray-900">
                        {t.contact}
                      </Link>
                      <div className="relative language-menu">
                        <button
                          onClick={() => setLanguageMenuOpen(!languageMenuOpen)}
                          className="flex items-center text-gray-700 hover:text-gray-900"
                        >
                          <Globe className="w-5 h-5 mr-1" />
                          <span className="uppercase mr-1">{language}</span>
                          <ChevronDown className="w-4 h-4" />
                        </button>
                        {languageMenuOpen && (
                          <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-lg shadow-xl z-20">
                            <Link
                              to="/"
                              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              English
                            </Link>
                            <Link
                              to="/es"
                              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              Español
                            </Link>
                            <Link
                              to="/pt"
                              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              Português
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                    <Link
                      to="/login"
                      className="inline-flex items-center justify-center px-6 py-2 border border-transparent text-base font-medium rounded-full text-white bg-gray-900 hover:bg-gray-800"
                    >
                      {t.signIn}
                    </Link>
                  </>
                )}
              </div>
            </div>
          </nav>
        </header>

        {/* Main Content */}
        <main className="flex-grow pt-16">
          <HeroSection 
            t={t} 
            isMobile={isMobile} 
            isTablet={isTablet} 
            containerRef={containerRef} 
            svgRef={svgRef} 
            language={language}
            setLanguageMenuOpen={setLanguageMenuOpen}
            languageMenuOpen={languageMenuOpen}
          />
          <FeaturesSection isMobile={isMobile} />
          <ImageGridSection isMobile={isMobile} isTablet={isTablet} containerRef2={containerRef2} svgRef2={svgRef2} />
          <ColorAnalysisSection isMobile={isMobile} isTablet={isTablet} />
          <PlaygroundSection isMobile={isMobile} isTablet={isTablet} />
          <TestimonialsSection isMobile={isMobile} />
          <PricingSection t={t} />
          <FAQSection />
        </main>

        <Footer />
      </div>
    </>
  );
};

export default LandingPage;