import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { CheckCircle2 } from 'lucide-react';
import { ClipLoader } from 'react-spinners';
import { Capacitor } from '@capacitor/core';

const Credits = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [isNativeMobile, setIsNativeMobile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsNativeMobile(Capacitor.isNativePlatform());
  }, []);

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/verify-token`, 
          { 
            token,
            appleToken: isNativeMobile ? localStorage.getItem('appleToken') : undefined
          }
        );
        if (!response.data.valid) {
          navigate('/login');
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Token verification failed:', error);
        navigate('/login');
      }
    };

    verifyToken();
  }, [navigate, isNativeMobile]);

  const creditPlans = [
    { 
      id: 'basic_credits', 
      name: 'Basic Bundle', 
      price: '$5',
      credits: 70,
      discount: 0
    },
    { 
      id: 'pro_credits', 
      name: 'Pro Bundle', 
      price: '$15',
      credits: 300,
      discount: 20
    },
    { 
      id: 'premium_credits', 
      name: 'Premium Bundle', 
      price: '$40',
      credits: 1000,
      discount: 30
    }
  ];

  const handlePlanSelection = async (planId) => {
    setSelectedPlan(planId);
    setIsProcessing(true);
    setError(null);

    const token = localStorage.getItem('token');
    const appleToken = isNativeMobile ? localStorage.getItem('appleToken') : undefined;

    if (!token && !appleToken) {
      navigate('/login');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/credits-checkout`, {
        token,
        appleToken,
        plan: planId,
        platform: isNativeMobile ? 'mobile' : 'web'
      });
        // Handle desktop payment
      if (response.data.checkoutUrl) {
          window.location.href = response.data.checkoutUrl;
        } else {
          throw new Error('Unexpected response from server');
        }
    } catch (error) {
      console.error('Credits checkout error:', error);
      setError('Failed to initiate checkout. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 to-gray-800">
        <ClipLoader color="#ffffff" loading={isLoading} size={50} />
      </div>
    );
  }

  return (
    <div className={`${isNativeMobile ? 'bg-pink-50' : 'bg-gradient-to-br from-gray-900 to-gray-800'} ${isNativeMobile ? 'text-black' : 'text-white'} min-h-screen flex flex-col`}>
      <div className="container mx-auto px-4 pt-16 pb-16">
        <div className="flex justify-center mb-12">
          {isNativeMobile ? (
            <img src="/logo.png" alt="Palette Hunt Logo" className="h-20 w-auto" />
          ) : (
            <Link to="/">
              <img src="/logo.png" alt="Palette Hunt Logo" className="h-20 w-auto cursor-pointer" />
            </Link>
          )}
        </div>
        
        <h1 className={`text-4xl sm:text-5xl font-bold mb-8 text-center ${isNativeMobile ? 'text-gray-800' : 'bg-gradient-to-r from-primary to-secondary text-transparent bg-clip-text'}`}>
          Recharge Your Creativity
        </h1>
        
        <p className={`text-xl ${isNativeMobile ? 'text-gray-700' : 'text-gray-300'} mb-12 text-center max-w-2xl mx-auto`}>
          Choose a credit package to continue generating stunning AI-powered images and exploring your perfect color palette.
        </p>
        
        {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
        
        <p className={`text-xl font-bold mb-6 text-center ${isNativeMobile ? 'text-gray-800' : 'text-white'}`}>
          Select Your Package
        </p>
        
        <div className="space-y-4 max-w-md mx-auto">
          {creditPlans.map((plan) => (
            <div 
              key={plan.id}
              className={`bg-gray-800 p-4 rounded-lg shadow-md ${selectedPlan === plan.id ? 'ring-2 ring-pink-500' : ''}`}
              onClick={() => handlePlanSelection(plan.id)}
            >
              <div className="flex justify-between items-center">
                <div>
                  <span className="text-lg font-semibold text-pink-50"><strong>{plan.credits}</strong> credits</span>
                  {plan.discount > 0 && (
                    <span className="text-pink-50 text-sm ml-2">(<strong>{plan.discount}%</strong> discount)</span>
                  )}
                </div>
                <span className="text-lg font-bold text-pink-50">{plan.price}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-8">
          <button
            onClick={() => handlePlanSelection(selectedPlan)}
            className="bg-gray-800 text-pink-50 font-semibold py-3 px-8 rounded-full hover:bg-opacity-90 transition duration-300 text-lg mb-8"
            disabled={!selectedPlan || isProcessing}
          >
            {isProcessing ? (
              <ClipLoader color="#ffe4e6" loading={isProcessing} size={24} />
            ) : (
              'Purchase Credits'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Credits;
